<template>
    <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" :isLarge="true" ref="form">
        <template v-slot:title>Detail Purchase Order - {{model.poNumber}}</template>
        <template v-slot:content>
            <bs-error-message :errors="errorMessages" />
            <div class="row">
                <div class="col-sm-6">
                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">PO Number</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.poNumber" />
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Vendor Code</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.vendorCode" />
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Vendor Name</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.vendorName" />
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">MIRO Doc</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.miroDoc" />
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">MIRO Year</label>
                        <div class="col-sm-8">
                            <view-date v-model="model.miroYear" />
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Payment Due Date</label>
                        <div class="col-sm-8">
                            <view-date v-model="model.paymentDueDate" />
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Payment Clearing</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.paymentClearing" />
                        </div>
                    </div>
                </div>
            </div>
            <div>Total Data : {{ model.purchaseOrderDetails.length }}</div>

            <div class="row">
                <div class="table-responsive" style="overflow:scroll; height: 66vh;">
                    <table class="table table-stripped">
                        <thead>
                            <tr>
                                <th>Vendor Invoice</th>
                                <th>Invoice Date</th>
                                <th>Submit Date</th>
                                <th>Receipt Number</th>
                                <th>Delivery Date</th>
                                <!-- <th>Qty in Gal</th> -->
                                <th>Qty</th>
                                <th>Unit</th>
                                <th>Total Price</th>
                                <th>Curr</th>
                                <th>SO</th>
                                <th>PR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="model.purchaseOrderDetails != null && model.purchaseOrderDetails.length > 0">
                                <tr v-for="item in model.purchaseOrderDetails" :key="item.id">
                                    <td>{{ item.vendorInvoice }}</td>
                                    <td><view-date v-model="item.invoiceDate" /></td>
                                    <td><view-date v-model="item.submitDate" /></td>
                                    <td>{{ item.receiptNumber }}</td>
                                    <td><view-date v-model="item.deliveryDate" /></td>
                                    <!-- <td><bs-plain-number v-model="item.qtyInGal" /></td> -->
                                    <td><bs-plain-number v-model="item.qty" /></td>
                                    <td>{{ item.unitCode }}</td>
                                    <td><bs-plain-number v-model="item.totalPrice" /></td>
                                    <td>USD</td>
                                    <td>{{ item.soNumber }}</td>
                                    <td>{{ item.prNumber }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>

        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import PurchaseOrderService from "@/services/purchase-order-service.js";
import ViewDate from '../../../components/ViewDate.vue';

export default {
    components: { ViewDate },
    data: function () {
        return {
            id: null,
            model: PurchaseOrderService.default(),
            errorMessages: null
        }
    },
    mounted() {
        let id = this.$route.params.id;
        this.id = id;
        this.loadData();
    },
    methods: {
        async loadData() {
            let response = await PurchaseOrderService.getObject(this.id);
            this.model = response.data;
            if (response.status) 
            {
                this.errorMessages = null;
            }
            else 
            {
                this.errorMessages = response.errorMessages;
            }
        },
        onPopupClosed() {
            this.$router.push('/purchase-order');
        },
        close() {
            this.$refs.form.closePopup();
        }
    }
}
</script>

<style scoped>
    th, td {
        white-space: nowrap;
    }
</style>